import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { DateTimeOptions } from 'luxon/src/datetime';

// Use luxon to manage dates
@Pipe({
  name: 'aliveDate',
  standalone: true,
})
export class AliveDatePipe implements PipeTransform {
  transform(
    value: Date | string | number,
    format?: string | Intl.DateTimeFormatOptions,
    timezone?: string,
  ): string | null {
    if (value == null || value === '' || value !== value) return null;

    let options: DateTimeOptions = {};
    if (timezone) {
      options = { zone: timezone };
    }

    //  TODO: Add Locale support
    if (typeof format === 'string') {
      return DateTime.fromISO(value.toString(), options)
        .setLocale($localize.locale ?? 'en-US')
        .toFormat(format);
    } else {
      return DateTime.fromISO(value.toString(), options)
        .setLocale($localize.locale ?? 'en-US')
        .toLocaleString(format);
    }
  }
}
